import React, { useEffect, useState } from "react";
import { parseCsv } from "./utils/parseCsv";
import ListePersonnes from "./components/ListePersonnes";
import Modale from "./components/Modale";

const App = () => {
  const [personnes, setPersonnes] = useState([]);
  const [recherche, setRecherche] = useState("");
  const [personneSelectionnee, setPersonneSelectionnee] = useState(null);
  const [modalOuverte, setModalOuverte] = useState(false);

  const ouvrirModale = (personne) => {
    setPersonneSelectionnee(personne);
    setModalOuverte(true);
  };

  const fermerModale = () => {
    setModalOuverte(false);
  };

  useEffect(() => {
    parseCsv("/data.csv", (result) => {
      setPersonnes(result.data);
    });
  }, []);

  const handleSearchChange = (e) => {
    setRecherche(e.target.value.toLowerCase());
  };

  const personnesFiltrees = personnes.filter((personne) =>
    `${personne.Prénom} ${personne.Nom}`.toLowerCase().includes(recherche)
  );

  return (
    <div>
      <h1>Annuaire des Membres CCE</h1>
      <input
        type="text"
        placeholder="Rechercher..."
        value={recherche}
        onChange={handleSearchChange}
        style={{ width: '150px', padding: '5px',margin: '20px', border: '1px solid #ccc', borderRadius: '4px' }} 
      />
      <ListePersonnes personnes={personnesFiltrees} onClick={ouvrirModale} />
      {modalOuverte && (
        <>
          <div className="fondModale" onClick={fermerModale}></div>
          <Modale personne={personneSelectionnee} fermer={fermerModale} />
        </>
      )}
    </div>
  );
};

export default App;
