import React from "react";
import CartePersonne from "./CartePersonne";
import "../styles/ListePersonnes.css";

const ListePersonnes = ({ personnes, onClick }) => {
  // Tri des personnes par ordre alphabétique du nom, avec gestion des noms manquants ou undefined
  const personnesTrie = personnes.sort((a, b) => {
    // Assure que les noms sont traités comme des chaînes vides s'ils sont undefined
    const nomA = a.nom || "";
    const nomB = b.nom || "";

    // Compare les noms; les entrées sans noms vont à la fin
    if (nomA < nomB) return -1;
    if (nomA > nomB) return 1;
    return 0;
  });

  return (
    <div className="liste-personnes">
      {personnesTrie.map((personne) => (
        <CartePersonne
          key={personne.id}
          personne={personne}
          onClick={onClick}
        />
      ))}
    </div>
  );
};

export default ListePersonnes;
