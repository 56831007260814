import React from "react";
import "../styles/Modale.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faBriefcase,
  faGlobeAmericas,
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

const Modale = ({ personne, fermer }) => {
  const mailToLink = `mailto:${personne["Adresse mail"]}`;
  const linkedinLink = `${personne.Linkedin}`;

  const photoExists = personne.Photo && personne.Photo !== "";
  const photoPath = photoExists
    ? `/images/${personne.Photo}`
    : "/images/profil.png";

  return (
    <div className="modale">
      <div className="section1">
        {/* <button onClick={fermer}>Fermer</button> */}
        <div className="présentation">
          {photoExists && (
            <img
              className="photoProfile"
              src={photoPath}
              alt={`Photo de ${personne.Prénom} ${personne.Nom}`}
            />
          )}
          <h2>
            {personne.Prénom} {personne.Nom}
          </h2>
        </div>
      </div>
      <div className="section2">
        <div className="infoSection">
          <p>
            <FontAwesomeIcon icon={faEnvelope} />{" "}
            <a href={mailToLink}>{personne["Adresse mail"]}</a>
          </p>
          <p>
            <FontAwesomeIcon icon={faLinkedin} />{" "}
            <a href={linkedinLink} target="_blank" rel="noopener noreferrer">
              Voir le profil Linkedin
            </a>
          </p>
          <div className="entreprise">
            <p>
              <FontAwesomeIcon icon={faBriefcase} /> {personne.Entreprise}
            </p>

            <p>{personne["Commentaire sur l'entreprise"]}</p>
          </div>
          <div className="commission">
            <p>
              {personne.Commission && <p>Commission: {personne.Commission}</p>}
              <FontAwesomeIcon icon={faGlobeAmericas} /> Spécialisation :{" "}
              {personne["Pays de spécialisation"]}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modale;
