import React from "react";
import "../styles/CartePersonne.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";

const CartePersonne = ({ personne, onClick }) => {
  return (
    <div className="carte-personne" onClick={() => onClick(personne)}>
      <div className="info-gauche">
        <img
          className="photoPers"
          src={`/images/${personne.Photo}`}
          alt={`Photo de ${personne.Prénom} ${personne.Nom}`}
        />
        <h2>
          {personne.Prénom} {personne.Nom}
        </h2>
      </div>
      <div className="info-droite">
        <p>
          <FontAwesomeIcon className="icon-user" icon={faUserTie} />
          {personne.Fonction}
        </p>
        <p>
          <FontAwesomeIcon className="icon-building" icon={faBuilding} />
          {personne.Entreprise}
        </p>
        <p>{personne["Commentaire sur l'entreprise"]}</p>
      </div>
    </div>
  );
};

export default CartePersonne;
